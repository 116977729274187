import { graphql } from "gatsby"
import React from "react"

import { Content, Heading, Layout, SEO, Stack, Text } from "../components"

const PostTemplate = ({ data }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={data.location} title={siteTitle}>
      <SEO description={post.excerpt} title={post.frontmatter.title} />
      <Stack gap="3xl">
        <Stack gap="lg">
          <Heading level={1} size="3xl" weight="black">
            {post.frontmatter.title}
          </Heading>
          {post.frontmatter.date && (
            <Text color="secondary" format="uppercase" size="xs" weight="bold">
              {post.frontmatter.date}
            </Text>
          )}
        </Stack>
        <Content>{post.body}</Content>
      </Stack>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      body
    }
  }
`
